import { getPlatforms } from '.';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';

/**
 *
 * @param {QueryParamsBuilder} params
 * @return {Promise<{data:PlatformDeals,,meta:EndpointMeta}>}
 */
export function getConnectedPlatforms(params = new QueryParamsBuilder()) {
  params.addFilter('provider.isConnected', '1');
  return getPlatforms(params);
}
