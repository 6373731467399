import { api } from '.';
import { PLATFORM_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import dashboardSSPEntityParser from '@/entities/dashboardSSP/DashboardSSPEntityParser';

/**
 *
 * @param {QueryParamsBuilder} params
 * @return {Promise<{data:PlatformDeals,,meta:EndpointMeta}>}
 */
export async function getPlatformById(platformId) {
  const params = new QueryParamsBuilder();
  const partialUrl = api.createUrl({ [PLATFORM_RESOURCE]: platformId });

  params.addInclude(['platform-fee', 'provider', 'client', 'emails']);
  const { data } = await api.get(partialUrl, params.build());
  return new ResponseBuilder(dashboardSSPEntityParser.parse(data), data.meta).build();
}
