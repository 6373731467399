import { PLATFORM_FEE_RESOURCE } from '@/services/keys';
import { api } from '.';

export async function createPlatformFee(platformFee) {
  const partialUrl = api.createUrl({
    [PLATFORM_FEE_RESOURCE]: undefined,
  });

  const { data } = await api.create(partialUrl, platformFee);
  return data;
}
