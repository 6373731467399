import { api } from '.';
import { PLATFORM_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import dashboardSSPEntityParser from '@/entities/dashboardSSP/DashboardSSPEntityParser';

/**
 *
 * @param {QueryParamsBuilder} params
 * @return {Promise<{data:PlatformDeals,,meta:EndpointMeta}>}
 */
export async function getAllConnectedPlatforms(params = new QueryParamsBuilder(), clientId) {
  const partialUrl = api.createUrl({
    [PLATFORM_RESOURCE]: undefined,
  });
  params.addInclude('platforms', 'sellers', 'provider', 'platform-fee');
  params.addFilter('provider.isConnected', '1');
  if (clientId) params.addFilter('client.id', clientId);
  const { data } = await api.get(partialUrl, params.buildWithoutPage());
  return new ResponseBuilder(dashboardSSPEntityParser.parse(data), data.meta).build();
}
