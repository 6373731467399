import { dashboardSSP } from '..';
import { createPlatformFee } from './createPlatformFee';
import { getConnectedPlatforms } from './getConnectedPlatforms';
import { getAllConnectedPlatforms } from './getAllConnectedPlatforms';
import { getPlatformById } from './getPlatformById';
import { getPlatforms } from './getPlatforms';
import { updatePlatform } from './updatePlatform';
import { updatePlatformFee } from './updatePlatformFee';
import getPlatformByName from './getPlatformByName';

export const api = dashboardSSP;
export {
  createPlatformFee,
  getConnectedPlatforms,
  getAllConnectedPlatforms,
  getPlatformById,
  getPlatformByName,
  getPlatforms,
  updatePlatform,
  updatePlatformFee,
};
