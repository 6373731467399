import { api } from '.';
import { PLATFORM_RESOURCE } from '@/services/keys';

/**
 *
 * @param {Platform} platform
 * @returns {Promise<object>}
 */
export function updatePlatform(platform) {
  const partialUrl = api.createUrl({
    [PLATFORM_RESOURCE]: platform.id,
  });

  return api.update(partialUrl, platform.payload());
}
