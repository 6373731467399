import { PLATFORM_FEE_RESOURCE } from '@/services/keys';
import { api } from '.';

/**
 * @param {PlatformFee}
 * @returns {Promise<string>}
 */
export async function updatePlatformFee(platformFee) {
  const partialUrl = api.createUrl({
    [PLATFORM_FEE_RESOURCE]: platformFee.id,
  });

  const { data } = await api.update(partialUrl, platformFee.payload());
  return data;
}
