import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import { getPlatforms } from './getPlatforms';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import dashboardSSPEntityParser from '@/entities/dashboardSSP/DashboardSSPEntityParser';

/**
 *
 * @param {string} platformId
 * @return {Promise<{data:PlatformDeals,meta:EndpointMeta}>}
 */
export default async function (platformName) {
  const params = new QueryParamsBuilder(1, 1);
  params.addFilter('name', platformName);
  const { data } = await getPlatforms(params);
  return new ResponseBuilder(dashboardSSPEntityParser.parse(data), data.meta).build();
}
